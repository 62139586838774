import { AppliedCouponType, useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useLocale } from "lib/hooks/useLocale";
import SavingsIcon from "assests/images/savings-cookie.svg";
import { SavingItemsType } from "lib/types/checkout";
import React, { useState } from "react";
import AppliedItem from "./AppliedItem";
import { ChevronDown } from "react-feather";
import Price from "./Price";

interface CouponSummaryStripProps {
  showAppliedCount: boolean;
  showRewards?: boolean;
  showSavings?: boolean;
  showAppliedCoupons?: boolean;
  savingItems: SavingItemsType;
  couponDisplayType?: string;
  handleDeleteCoupon: (couponCode: string) => void;
  defaultOpen?: boolean;
  showIcon?: boolean;
}

const CouponSummaryStrip: React.FC<CouponSummaryStripProps> = ({
  showAppliedCount,
  showRewards = false,
  showSavings = true,
  showAppliedCoupons = false,
  savingItems,
  couponDisplayType,
  handleDeleteCoupon,
  defaultOpen = false,
  showIcon = true,
}) => {
  const { t } = useLocale();
  const {
    state: { appliedCoupons, checkoutView },
  } = useCheckoutContext();

  const [couponInputEnabled, setCouponInputEnabled] = useState<boolean>(defaultOpen);

  const { count, manualFreebieCount, savings } = savingItems;
  const rewards = appliedCoupons?.filter((coupon) => coupon.isReward) || [];
  const appliedCouponsVisible = showAppliedCoupons || Boolean(couponDisplayType !== "STRIP");

  const toggleCouponInput = () => {
    setCouponInputEnabled(!couponInputEnabled);
  };

  if (savings === 0 && couponDisplayType === "STRIP" && !showRewards) {
    return <></>;
  }

  return (
    <div className={`flex w-full flex-col`}>
      <div
        className="flex w-full flex-col cursor-pointer divide-y divide-[#2C874A1A]"
        onClick={() => {
          if (appliedCouponsVisible) {
            toggleCouponInput();
          }
        }}>
        {showRewards &&
          rewards.map((reward) => (
            <div
              className="flex items-center space-x-2 px-4 py-3 text-sm font-medium text-yay-dark"
              key={reward.code}>
              <span className="flex h-4 w-4 items-center justify-center rounded-full border border-[#2C874A80] text-xxs">
                ₹
              </span>
              <span>{reward?.rewardData?.header}</span>
            </div>
          ))}
        {showSavings && count > 0 && (
          <div
            className={`justify-left relative flex items-center overflow-hidden min-h-10 py-0.5  ${
              couponInputEnabled
                ? "border-b border-yay-light  ease-in-out"
                : "border-b-0 delay-[650ms] ease-in"
            }`}>
            {showIcon && (
              <img
                className="spin-slow absolute left-4 top-3 h-4 min-h-4 w-4 min-w-4"
                src={SavingsIcon}
                alt="%"
              />
            )}
            <div className={`flex flex-col py-2.5 ${showIcon ? "pl-10" : "pl-3"} pr-3`}>
              <div className="flex w-full flex-row">
                <div className="flex w-full flex-row items-center justify-between overflow-hidden pr-2">
                  <p className="flex items-center space-x-1 truncate pr-2 text-sm font-normal text-yay-dark">
                    {manualFreebieCount !== count && (
                      <>
                        <Price total={savings} />
                        &nbsp;
                        {t("total_savings").toLowerCase()}
                      </>
                    )}
                    {showAppliedCount && manualFreebieCount !== count && <>&nbsp;·&nbsp;</>}
                    {showAppliedCount && t("x_coupons_applied", { count })}
                  </p>
                </div>
              </div>
            </div>
            {/* Arrow for dropdown when no discounts are available */}
            {appliedCouponsVisible &&
              Boolean(
                appliedCoupons?.filter(
                  (coupon) =>
                    !(coupon?.isFreebie && coupon?.autoApplied && !coupon?.isRemovable) && !coupon?.isReward,
                )?.length,
              ) && (
                <ChevronDown
                  className={`!ml-auto !mr-4 h-4 w-4 text-coal-dark ${
                    couponInputEnabled
                      ? `rotate-180 transform duration-700 ease-in-out`
                      : `rotate-0 transform duration-700 ease-in-out`
                  }`}
                />
              )}
            {/* Arrow for dropdown when no discounts are available */}
          </div>
        )}
      </div>
      <div
        className={`relative  overflow-hidden transition-[max-height] ${
          couponInputEnabled ? `duration-1000 ease-in` : `duration-700 ease-out`
        } ${couponInputEnabled ? `max-h-[9999px]` : ` max-h-0`} `}>
        {appliedCoupons
          ?.filter(
            (coupon) =>
              !(coupon?.isFreebie && coupon?.autoApplied && !coupon?.isRemovable) && !coupon?.isReward,
          )
          ?.map((coupon: AppliedCouponType, index: number) => {
            return (
              <AppliedItem
                key={`${coupon?.code}_${index}`}
                coupon={coupon}
                handleDeleteItem={handleDeleteCoupon}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CouponSummaryStrip;
