import React, { useCallback, useState } from "react";
import { ItemType } from "lib/types/checkout";
import {
  classNames,
  insertBeforeLastOccurrence,
  isEmptyObj,
  publishPostMessage,
  sleep,
} from "lib/utils/helpers";
import { Gift } from "react-feather";
import defaultPackageImage from "assests/images/package.png";
import Price from "components/common/Price";
import { useLocale } from "lib/hooks/useLocale";
import {
  getItemPropertiesValues,
  getFileredItemProperties,
  getItems,
  publishItemUpdate,
  isImmutablePriceBand,
} from "lib/utils/checkout";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { Minus, Plus, Trash2 } from "react-feather";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { getRequest, putRequest } from "lib/core/apiClient";
import { errorToast } from "lib/utils/toasters";
import { mutate } from "swr";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import RemoveItemDialog from "components/checkout/dialogs/RemoveItemDialog";
import LinkButton from "components/common/buttons/LinkButton";
import { redirectUrl } from "lib/utils/helpers";
import { ShippingHandlesType } from "lib/types/checkout";
import { publishCartUpdate } from "lib/utils/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { analyticsEvents, analyticsTypes, eventTypes } from "lib/utils/constants";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";

const MIN_ITEM_QUANTITY = 1;

interface CheckoutItemsProps {
  items: ItemType[];
  disableOOSItems?: boolean;
  checkoutItemsMutable?: boolean;
}

const CheckoutItems: React.FC<CheckoutItemsProps> = React.memo(
  ({ items, disableOOSItems = false, checkoutItemsMutable = false }) => {
    return (
      <>
        <ul className="flex w-full flex-col space-y-4">
          {items?.map((item: ItemType, index: number) => (
            <li key={`${item.item_id}_${index}`} className={`py-1.5 ${item.is_freebie ? "px-1.5" : "px-3"}`}>
              <ItemCard
                {...item}
                disableOOSItems={disableOOSItems}
                checkoutItemsMutable={checkoutItemsMutable}
              />
            </li>
          ))}
        </ul>
      </>
    );
  },
);

interface ItemCardProps extends ItemType {
  disableOOSItems: boolean;
  checkoutItemsMutable: boolean;
  isCartItem?: boolean;
  isUpdating?: boolean;
  setIsUpdating?: (value: boolean) => void;
}

export const ItemCard: React.FC<ItemCardProps> = ({
  item_title,
  quantity,
  price,
  originalPrice,
  markupPrice,
  image,
  variant_title,
  is_freebie,
  is_available,
  disableOOSItems,
  item_properties,
  availableQuantity,
  item_id,
  variant_id,
  isMutable,
  checkoutItemsMutable,
  isCartItem = false,
  isUpdating = false,
  setIsUpdating,
  item_url,
  is_discount_alteration,
  is_platform_fee,
}) => {
  const { t } = useLocale();
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: {
      checkoutId,
      checkoutView,
      billing,
      checkoutItems,
      checkoutUIMetadata,
      redirectUrl: backUrl,
      initialCheckoutStep,
    },
    actions: {
      updateCheckoutBasedOnCheckoutResponse,
      setShippingHandles,
      setCheckoutModal,
      setCheckoutItems,
      setCoupons,
    },
  } = useCheckoutContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const finalItemPrice: number = parseFloat(price) * quantity;
  const finalCompareAtPrice: number =
    parseFloat(Boolean(merchant?.showOriginalPrice) ? originalPrice : markupPrice) * quantity;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openRemoveItemDialog, setOpenRemoveItemDialog] = useState<boolean>(false);

  const getItemPrices = useCallback(() => {
    return (
      <>
        <p className="pt-1 text-sm font-medium text-coal-dark">
          <Price
            total={finalItemPrice ?? ""}
            compareAt={finalCompareAtPrice}
            orientation={Boolean(isCartItem) ? "horizontal" : "vertical"}
          />
        </p>
      </>
    );
  }, [finalCompareAtPrice, finalItemPrice, isCartItem]);

  const getItemProperties = useCallback(() => {
    if (isEmptyObj(item_properties)) return <></>;

    if (!Boolean(merchant?.showAllItemProperties) && !Boolean(merchant?.itemProperties?.length)) return <></>;

    try {
      if (Boolean(merchant?.showItemPropertyKey)) {
        const properties = getFileredItemProperties(
          item_properties,
          merchant?.itemProperties ?? [],
          merchant?.showAllItemProperties ?? false,
        );
        if (isEmptyObj(properties)) return <></>;
        const propertiesList = [];
        for (let key in properties) {
          if (properties.hasOwnProperty(key)) {
            propertiesList.push(
              <li className="w-full overflow-hidden text-ellipsis text-xs font-normal text-coal-light">
                {`${key}: ${properties[key]}`}
              </li>,
            );
          }
        }
        return propertiesList;
      }

      return (
        <li className="w-full overflow-hidden text-ellipsis text-xs font-normal text-coal-light">
          {getItemPropertiesValues(
            item_properties,
            merchant?.itemProperties ?? [],
            merchant?.showAllItemProperties ?? false,
          )}
        </li>
      );
    } catch (e) {
      console.error(e);
      return <></>;
    }
  }, [item_properties, merchant]);

  const handleItemEdit = useCallback(
    async (action: "increment" | "decrement" | "delete") => {
      let newQuantity = quantity;
      switch (action) {
        case "increment": {
          if (
            Boolean(quantity + 1 > availableQuantity) ||
            (merchant?.maxQuantityPerItem &&
              Boolean(merchant?.maxQuantityPerItem) &&
              quantity + 1 > merchant?.maxQuantityPerItem)
          )
            return;
          newQuantity++;
          break;
        }
        case "decrement": {
          if (quantity === MIN_ITEM_QUANTITY) return;
          newQuantity--;
          break;
        }
        case "delete": {
          newQuantity = 0;
          setIsDeleting(true);
          break;
        }
      }
      try {
        setIsLoading(true);
        setIsUpdating?.(true);
        const payload = {
          items: [
            {
              id: item_id,
              quantity: newQuantity,
            },
          ],
        };
        let response;
        if (isAuthenticated) {
          response = await putRequest(`/checkout/v1/checkout/${checkoutId}/items`, payload);
          const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
          setCoupons(discountResponse);
        } else {
          response = await putRequest(`/v1/checkout/${checkoutId}/items`, payload, "CHECKOUT_PUBLIC");
          const discountResponse = await getRequest(
            `/v1/checkout/${checkoutId}/discounts`,
            "CHECKOUT_PUBLIC",
          );
          setCoupons(discountResponse);
        }

        if (Boolean(response?.items)) {
          const cartItems = getItems(response?.items);
          publishItemUpdate(item_id, action === "delete" ? checkoutItems : cartItems, action, isCartItem);
          setCheckoutItems(cartItems);
          if (!cartItems || cartItems?.length === 0) {
            publishCartUpdate([]);
            handleItemDeleteSubmit(true);
            return;
          }
        }

        if (isCartItem && action === "increment") {
          sendAnalyticsEvent({
            eventName: analyticsEvents.FLO_ADDED_TO_CART_UI,
            eventType: "click",
            eventFor: [analyticsTypes.FACEBOOK_PIXEL, analyticsTypes.SF_ANALYTICS],
            metaData: {
              checkoutItems: getItems(response?.items),
            },
          });
        }

        setIsLoading(false);
        setIsUpdating?.(false);
        setIsDeleting(false);

        const isAddressServiceable = response?.pricing?.serviceable ?? false;
        if (!Boolean(isAddressServiceable) && !isCartItem) {
          errorToast(t("serviceability_error"), 5000);
          return;
        }

        updateCheckoutBasedOnCheckoutResponse(response);

        const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
        const shippingHandles = response?.metadata?.available_shipping_handles ?? [];
        setShippingHandles(shippingHandles as ShippingHandlesType);

        if (Boolean(checkoutView === "PAYMENTS")) {
          if (Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled)) {
            isAuthenticated
              ? mutate(`/checkout/v1/checkout/${checkoutId}/upsell`)
              : mutate(`/v1/checkout/${checkoutId}/upsell`);
          }
          mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        }

        if (isCartItem && Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled)) {
          isAuthenticated
            ? mutate(`/checkout/v1/checkout/${checkoutId}/upsell`)
            : mutate(`/v1/checkout/${checkoutId}/upsell`);
        }

        if (
          Boolean(showShippingHandles) &&
          Boolean(checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS")
        ) {
          setCheckoutModal("SHIPPING_HANDLES");
          return;
        }
        mutate(`UPI_INTENT`);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setIsUpdating?.(false);
        setIsDeleting(false);
      }
    },
    [
      availableQuantity,
      billing.shipping,
      checkoutId,
      checkoutView,
      isAuthenticated,
      item_id,
      quantity,
      checkoutItems,
      variant_id,
    ],
  );

  const getItemPriceElement = useCallback(() => {
    return (
      <>
        {Boolean(disableOOSItems) ? (
          Boolean(is_available) ? (
            <>{getItemPrices()}</>
          ) : (
            <>
              <p className="whitespace-nowrap pt-0.5 text-sm font-medium text-ouch">{t("oos_item")}</p>
            </>
          )
        ) : (
          getItemPrices()
        )}
      </>
    );
  }, [disableOOSItems, getItemPrices, is_available, t]);

  const handleItemDeleteSubmit = async (isLastItem: boolean) => {
    //handling redirect to store on FE since BE can't do it as of now
    if (isLastItem) {
      publishCartUpdate([]);
      await sleep(50);
      // await handleItemEdit("delete");
      if (isCartItem) {
        publishPostMessage(eventTypes.CLEAR_CART_AND_CLOSE, {});
      } else {
        redirectUrl(`${backUrl}/?floRedirectStatus=oos`);
      }
      setOpenRemoveItemDialog(false);
      return;
    }
    try {
      await handleItemEdit("delete");
    } catch (e) {
      console.error(e);
    } finally {
      setOpenRemoveItemDialog(false);
    }
  };

  return (
    <>
      <div
        className={classNames(
          `flex w-full flex-row justify-between`,
          is_freebie ? "rounded-2xl bg-gradient-to-r from-yay-light to-yay-lighter p-1.5 pr-2" : "",
          isCartItem ? "rounded-xl bg-[#F8F8F8] p-2" : "",
        )}>
        <div className="flex w-full space-x-4">
          <div
            className={classNames(
              "relative flex items-center justify-center ",
              isCartItem ? "min-h-[75px] min-w-[75px]" : "h-16 min-h-[4rem] w-16 min-w-[4rem]",
            )}>
            {is_freebie ? (
              <div className="absolute bottom-[1px] right-[1px] flex h-6 w-6 items-center justify-center rounded-md rounded-bl-none rounded-tr-none bg-white shadow-sm">
                <Gift className="h-4 w-4 text-yay-dark" strokeWidth={2.5} />
              </div>
            ) : (
              <></>
            )}
            <LinkButton href={isCartItem ? item_url : ""}>
              <img
                src={insertBeforeLastOccurrence(image, ".jpg", "_small")}
                alt="Cart Item"
                className={classNames(
                  `rounded-lg border border-gray-light object-cover`,
                  disableOOSItems && !is_available ? "grayscale" : "",
                  isCartItem ? "h-[75px] w-[75px]" : "h-[4.188rem] w-16",
                )}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultPackageImage;
                }}
              />
            </LinkButton>

            {!Boolean(checkoutItemsMutable) && quantity > 1 && (
              <div className="absolute -right-3 -top-3 flex h-6 w-6 items-center justify-center rounded-full border border-gray-light bg-[#F8F8F8]">
                <span className="text-xs font-medium text-coal-dark">{quantity}</span>
              </div>
            )}
          </div>

          <ul className="flex grow flex-col items-start justify-start space-y-0.5">
            <li className="flex w-full flex-row items-start justify-between space-x-3">
              <LinkButton href={isCartItem ? item_url : ""}>
                <p className="overflow-hidden text-ellipsis  pt-0.5 text-sm font-medium text-coal-dark">
                  {item_title}
                </p>
              </LinkButton>
              {((Boolean(checkoutItemsMutable) && Boolean(isMutable)) ||
                (isCartItem && !is_freebie && !is_discount_alteration && !is_platform_fee)) && (
                <button
                  className={classNames(
                    "cursor-pointer rounded-lg border border-gray-light p-1",
                    isCartItem ? "bg-white" : "",
                  )}
                  disabled={isUpdating || isLoading}
                  onClick={() => setOpenRemoveItemDialog(true)}>
                  <Trash2 className="h-3.5 w-3.5 text-coal-light outline-none" strokeWidth={2.5} />
                </button>
              )}
            </li>
            {variant_title !== "Default Title" && (
              <li className="w-full overflow-hidden text-ellipsis text-xs font-normal text-coal-light">
                {variant_title}
              </li>
            )}

            {getItemProperties()}

            {Boolean(checkoutItemsMutable) && !Boolean(disableOOSItems) && (
              <>
                <li className="flex w-full flex-row items-end justify-between pt-1">
                  {Boolean(finalItemPrice) &&
                  !isImmutablePriceBand(markupPrice, merchant) &&
                  (Boolean(isMutable) ||
                    (isCartItem && !is_freebie && !is_discount_alteration && !is_platform_fee)) ? (
                    <>
                      <div
                        className={classNames(
                          "flex h-6 w-[4.25rem] flex-row items-center rounded border",
                          isCartItem ? "border-carbon-lighter bg-white" : "border-gray-light",
                        )}>
                        <button
                          className={classNames(
                            `flex h-full w-full grow basis-1/3 items-center justify-center`,
                            Boolean(quantity === MIN_ITEM_QUANTITY) || isUpdating || isLoading
                              ? "cursor-not-allowed text-carbon-lighter"
                              : "cursor-pointer bg-gray-light text-coal-light",
                            "rounded-l",
                            isCartItem ? "bg-[#F8F8F8]" : "",
                          )}
                          disabled={isUpdating || isLoading}
                          onClick={() => handleItemEdit("decrement")}>
                          <Minus className="h-3.5 w-3.5 outline-none" strokeWidth={2.5} />
                        </button>

                        <p className="grow basis-1/3 text-center text-xs font-medium text-coal-dark">
                          {quantity}
                        </p>
                        <button
                          className={classNames(
                            `flex h-full w-full grow basis-1/3 items-center justify-center rounded-r`,
                            Boolean(quantity + 1 > availableQuantity) ||
                              (merchant?.maxQuantityPerItem &&
                                Boolean(merchant?.maxQuantityPerItem) &&
                                quantity + 1 > merchant?.maxQuantityPerItem) ||
                              isUpdating ||
                              isLoading
                              ? "cursor-not-allowed text-carbon-lighter"
                              : "cursor-pointer bg-gray-light text-coal-light",
                            isCartItem ? "bg-[#F8F8F8]" : "",
                          )}
                          disabled={isUpdating || isLoading}
                          onClick={() => handleItemEdit("increment")}>
                          <Plus className="h-3.5 w-3.5 outline-none" strokeWidth={2.5} />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="flex h-6 w-6 items-center justify-center rounded border border-gray-light bg-white">
                      <p className="text-xs font-medium text-coal-dark">{quantity}</p>
                    </div>
                  )}

                  {getItemPriceElement()}
                </li>
              </>
            )}
          </ul>
        </div>
        {!Boolean(checkoutItemsMutable) && getItemPriceElement()}
      </div>

      <GenericDialog
        isOpen={openRemoveItemDialog}
        setIsOpen={() => setOpenRemoveItemDialog(false)}
        translateAxis="y"
        customClass="overflow-scroll md:!top-auto md:absolute"
        dialogOverlay={true}
        modalType="REMOVE_ITEM">
        <RemoveItemDialog
          setIsOpen={() => setOpenRemoveItemDialog(false)}
          itemName={item_title}
          onConfirm={handleItemDeleteSubmit}
          isCartItem={isCartItem}
        />
      </GenericDialog>

      {((!isCartItem && isLoading) || isDeleting) && <OverlaySpinner />}
    </>
  );
};

export default CheckoutItems;
