import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

const useSentry = () => {
  const [isSentryLoaded, setIsSentryLoaded] = useState(false);

  useEffect(() => {
    const dsn = import.meta.env.VITE_SENTRY_DNS;
    let replaysSessionSampleRate = 1.0;
    let enabled = false;
    if (import.meta.env.VITE_NODE_ENV === "production") {
      replaysSessionSampleRate = 0.1;
      enabled = true;
    }

    if (dsn && !isSentryLoaded && enabled) {
      Sentry.init({
        dsn,
        enabled,
        // release: sentryConfig.release, //Todo: add a release version
        environment: import.meta.env.VITE_NODE_ENV,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampler(samplingContext) {
          if (samplingContext.parentSampled !== undefined) {
            return samplingContext.parentSampled;
          }

          return 0.1;
        },
        replaysSessionSampleRate,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [
          /^Clarity/,
          // case of no network on user's device
          /TypeError: Network request failed/,
          // /ChunkLoadError/,
          /**
           * safe to ignore as these are
           * https://stackoverflow.com/questions/71280168/javascript-typeerror-load-failed-error-when-calling-fetch-on-ios
           */
          /TypeError: Load failed/,
          /TypeError: Failed to fetch dynamically imported module/,
          /TypeError: Importing a module script failed/,
          /TypeError: error loading dynamically imported module/,
          /TypeError: cancelled/,
        ],
        denyUrls: [
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
        ],
      });
      setIsSentryLoaded(true);
    }
    // if (user) {
    //   Sentry.setUser({
    //     id: user.userUuid,
    //     email: user.email,
    //     username: user.email,
    //   });
    //   Sentry.setTag("organization", user.organizationUuid);
    // }
  }, [isSentryLoaded, setIsSentryLoaded]);
};

export default useSentry;
