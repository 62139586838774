import axios from "axios";
import Cookies from "js-cookie";
import { constants } from "lib/utils/constants";
import { refreshExpiredIdToken, expireFloAuthCookies } from "lib/utils/auth";
import { isBraveBrowser } from "lib/utils/helpers";

const baseURL = import.meta.env.VITE_API_URI;
const publicBaseURL = import.meta.env.VITE_API_PUBLIC_URI;
const analyticsURL = import.meta.env.VITE_ANALYTICS_API_URI;
const authBaseURL = import.meta.env.VITE_API_AUTH_URI;
const kratosBaseURL = import.meta.env.VITE_KRATOS_API_URI;
const kratosPrivateBaseURL = import.meta.env.VITE_API_KRATOS_PRIVATE_URI;
const version = window.location.pathname.split("/")[1];

/**
 * Axios Response Interceptor to retry on response error/failures.
 *
 * @param  {number} retryCount      The number of retries
 *
 */
const axiosRetryInterceptor = (retryCount: number) => {
  let counter = 0;
  axios.interceptors.response.use(undefined, (error) => {
    const config = error.config;
    if (counter < retryCount) {
      counter++;
      return new Promise((resolve) => {
        resolve(axios(config));
      });
    }
    return Promise.reject(error);
  });
};

const axiosRefreshTokenInterceptor = () => {
  let isRefreshing = false;
  let failedQueue: any[] = [];
  let retryCount = 0;
  const MAX_RETRIES = 3;

  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (Boolean(error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry && retryCount < MAX_RETRIES) {
        retryCount++;
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = token;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const refreshSuccess = await refreshExpiredIdToken(undefined, true);
          if (refreshSuccess) {
            const newToken = Cookies.get(constants.AUTH_COOKIE_CLIENT);
            axios.defaults.headers.common["Authorization"] = newToken;
            originalRequest.headers["Authorization"] = newToken;
            processQueue(null, newToken);
            return axios(originalRequest);
          } else {
            throw new Error("Token refresh failed");
          }
        } catch (refreshError) {
          processQueue(refreshError, null);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      retryCount = 0;
      return Promise.reject(error);
    },
  );
};

axiosRefreshTokenInterceptor();

/**
 * Requests a URL, returning a data/error using SWR library. Use this for GET calls
 *
 * @param  {string} url       The URL we want to request
 *
 * @return {object}           The response data
 */
export const fetcher = async (url: string) => {
  await refreshExpiredIdToken();
  const sessionId = Cookies.get(constants.FLO_SESSION_ID_COOKIE);
  const config = {
    headers: {
      Authorization: Boolean(Cookies.get(constants.AUTH_COOKIE_CLIENT))
        ? Cookies.get(constants.AUTH_COOKIE_CLIENT) ?? ""
        : Boolean(Cookies.get(constants.TWO_STEP_AUTH_COOKIE_CLIENT))
        ? Cookies.get(constants.TWO_STEP_AUTH_COOKIE_CLIENT) ?? ""
        : "",
      ...(sessionId && { "x-shopflo-session": sessionId }),
      ...(version && { "X-SHOPFLO-VERSION": version }),
    },
  };
  return axios
    .get(`${baseURL}${url}`, config)
    .then((res) => res?.data?.response)
    .catch((err) => {
      throw err;
    });
};

/**
 * Requests a URL, returning a data/error using SWR library. Use this for GET calls
 * use this to access public endpoints
 *
 * @param  {string} url       The URL we want to request
 *
 * @return {object}           The response data
 */
export const publicFetcher = (url: string) => {
  const sessionId = Cookies.get(constants.FLO_SESSION_ID_COOKIE);
  const config = {
    headers: {
      ...(sessionId && { "x-shopflo-session": sessionId }),
      ...(version && { "X-SHOPFLO-VERSION": version }),
    },
  };
  return axios
    .get(`${publicBaseURL}${url}`, config)
    .then((res) => res?.data?.response)
    .catch((err) => {
      throw err;
    });
};

export const staticOptions = {
  revalidateOnMount: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  errorRetryInterval: 5000,
  errorRetryCount: 1,
};

type BaseApi =
  | "CHECKOUT"
  | "CHECKOUT_PUBLIC"
  | "AUTH"
  | "ANALYTICS"
  | "AUTH_PRIVATE"
  | "KRATOS_PRIVATE"
  | "KRATOS";

const getRequestParams = (baseApi: BaseApi = "CHECKOUT") => {
  let headers = {
    [constants.FLO_SESSION_ID_XHR_HEADER]: Cookies.get(constants.FLO_SESSION_ID_COOKIE) ?? "NA",
    "X-SHOPFLO-VERSION": version,
  };
  let base: string = "";
  let token = Boolean(Cookies.get(constants.AUTH_COOKIE_CLIENT))
    ? Cookies.get(constants.AUTH_COOKIE_CLIENT)
    : Boolean(Cookies.get(constants.TWO_STEP_AUTH_COOKIE_CLIENT))
    ? Cookies.get(constants.TWO_STEP_AUTH_COOKIE_CLIENT)
    : "";

  switch (baseApi) {
    case "CHECKOUT": {
      headers = {
        ...headers,
        Authorization: token ?? "",
      };
      base = baseURL ?? "";
      break;
    }
    case "CHECKOUT_PUBLIC": {
      base = publicBaseURL ?? "";
      break;
    }
    case "AUTH": {
      base = authBaseURL ?? "";
      break;
    }
    case "AUTH_PRIVATE": {
      headers = {
        ...headers,
        Authorization: token ?? "",
      };
      base = authBaseURL ?? "";
      break;
    }
    case "KRATOS": {
      base = kratosBaseURL ?? "";
      headers = {
        ...headers,
        Authorization: token ?? "",
      };
      break;
    }
    case "KRATOS_PRIVATE": {
      headers = {
        ...headers,
        Authorization: token ?? "",
      };
      base = kratosPrivateBaseURL ?? "";
      break;
    }

    case "KRATOS": {
      headers = {
        ...headers,
        Authorization: token ?? "",
      };
      base = kratosBaseURL ?? "";
      break;
    }
  }
  return { headers, base };
};

const getResponseParams = (baseApi: BaseApi = "CHECKOUT", response: any) => {
  let data = {};
  switch (baseApi) {
    case "CHECKOUT": {
      data = response?.data?.response;
      break;
    }
    case "CHECKOUT_PUBLIC": {
      data = response?.data?.response;
      break;
    }
    case "AUTH": {
      data = response?.data?.data;
      break;
    }
    case "AUTH_PRIVATE": {
      data = response?.data?.data;
      break;
    }
    case "KRATOS": {
      data = response?.data;
      break;
    }
    case "KRATOS_PRIVATE": {
      data = response?.data?.data;
      break;
    }
  }
  return data;
};

export const postRequest = async (
  url: string,
  payload: any,
  baseApi: BaseApi = "CHECKOUT",
  customHeaders?: Record<string, string>,
) => {
  Boolean(baseApi === "CHECKOUT") && (await refreshExpiredIdToken());
  const { headers, base } = getRequestParams(baseApi);
  try {
    const response = await axios.post(`${base}${url}`, payload, {
      headers: Boolean(customHeaders) ? { ...customHeaders, ...headers } : headers,
    });
    const data: any = getResponseParams(baseApi, response);
    return data;
  } catch (err: any) {
    throw err;
  }
};

export const putRequest = async (
  url: string,
  payload: any,
  baseApi: BaseApi = "CHECKOUT",
  retryCount: number = 0,
  returnRaw: boolean = false,
) => {
  Boolean(baseApi === "CHECKOUT") && (await refreshExpiredIdToken());
  Boolean(retryCount) ?? axiosRetryInterceptor(retryCount);

  const { headers, base } = getRequestParams(baseApi);
  try {
    const response = await axios.put(`${base}${url}`, payload, {
      headers: headers,
    });
    if (returnRaw) return response;
    return response?.data?.response;
  } catch (err) {
    throw err;
  }
};

export const getRequest = async (url: string, baseApi: BaseApi = "CHECKOUT") => {
  Boolean(baseApi === "CHECKOUT") && (await refreshExpiredIdToken());
  const { headers, base } = getRequestParams(baseApi);
  try {
    const response = await axios.get(`${base}${url}`, {
      headers: headers,
    });
    const data: any = getResponseParams(baseApi, response);
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteRequest = async (url: string, payload?: any, baseApi: BaseApi = "CHECKOUT") => {
  Boolean(baseApi === "CHECKOUT") && (await refreshExpiredIdToken());
  const { headers, base } = getRequestParams(baseApi);
  try {
    const response = await axios.delete(`${base}${url}`, {
      data: payload,
      headers: headers,
    });
    return response?.data?.response;
  } catch (err) {
    throw err;
  }
};

/** Posts analytics data to be process via shopflo's query engine */
export const postAnalytics = async (payload?: any): Promise<boolean> => {
  try {
    const isBrave = await isBraveBrowser();
    if ((navigator as any)?.sendBeacon && Blob && !isBrave) {
      const blob = new Blob([JSON.stringify(payload)], );
      return navigator.sendBeacon(`${analyticsURL}`, blob);
    } else {
      await axios.post(`${analyticsURL}`, payload);
      return true
    }
  } catch (error) {
    throw error;
  }
};

/** Post survey data to be process via shopflo's query engine */
export const postSurvey = async (payload?: any, checkoutId?: string) => {
  try {
    await postRequest(`/checkout/v1/checkout/${checkoutId}/survey`, payload);
  } catch (error) {
    throw error;
  }
};
