import React, { useCallback, useMemo } from "react";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { BannerType } from "lib/types/checkout";
import { hexToRgba } from "lib/utils/helpers";
import {  isLightColor, shadeColor } from "@shopflo/ui";

interface CartBannerBottomProps {
  bannerPosition?: BannerType;
}

const CartBannerStrip: React.FC<CartBannerBottomProps> = ({ bannerPosition = BannerType.HEADER }) => {
  const {
    state: { checkoutUIMetadata },
  } = useCheckoutContext();

  const bannerConfig = useMemo(() => {
    const bannersConfig = bannerPosition === BannerType.HEADER ? checkoutUIMetadata?.bannerConfig : checkoutUIMetadata?.bottomBannerConfig;
    if (!bannersConfig) return null;

    let currentBanner = bannersConfig.find((banner) => banner.type === "CART");
    if (!currentBanner && bannerPosition !== BannerType.FOOTER) {
      currentBanner = bannersConfig.find((banner) => banner.type === "ORDER_SUMMARY");
    }

    if (!currentBanner || !currentBanner.enabled) return null;

    return {
      htmlString: currentBanner.htmlString ?? "",
      backgroundColor: currentBanner.bagroundColor ?? "black",
      textColor: currentBanner.textColor ?? "white",
    };
  }, [bannerPosition, checkoutUIMetadata]);

  const getBorderColor = useCallback((backgroundColor: string) => {
    return hexToRgba(backgroundColor.substring(0, 7), 0.5);
  }, []);

  if (!bannerConfig) return <></>;

  return (
    <div
      className={`sticky top-0 z-20 flex min-h-[33px] w-full items-center justify-center px-3 py-2 text-center text-sm ${
        bannerPosition === BannerType.HEADER ? "rounded-b-xl" : "rounded-t-xl"
      }`}
      style={{
        backgroundColor: bannerConfig.backgroundColor,
        color: bannerConfig.textColor,
        boxShadow: `0px ${bannerPosition === BannerType.HEADER ? "1" : "-1"}px 5px 0px rgba(0, 0, 0, 0.2)`,
        borderBottom:
          bannerPosition === BannerType.HEADER
            ? `1px solid ${getBorderColor(bannerConfig.backgroundColor)}`
            : "",
        borderTop:
          bannerPosition === BannerType.FOOTER
            ? `1px solid ${getBorderColor(bannerConfig.backgroundColor)}`
            : "",
      }}
      dangerouslySetInnerHTML={{ __html: bannerConfig.htmlString }}
    />
  );
};

export default CartBannerStrip;
