import { PAYUIframeData } from "lib/types/payments";
import { loadScript } from "lib/utils/helpers";

const PAYU_SCRIPT_URL = "https://jssdk.payu.in/bolt/bolt.min.js";
const PAYU_SCRIPT_URL_TEST = "https://jssdk-uat.payu.in/bolt/bolt.min.js";

export const loadPayUDefaultSdk = async () => {
  try {
    const response = await loadScript(
      import.meta.env.VITE_NODE_ENV === "staging" ? PAYU_SCRIPT_URL_TEST : PAYU_SCRIPT_URL,
      () => {
        (window as any).payu = (window as any).bolt;
      },
    );

    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const initializePayuDefaultPayment = async ({
  data,
  onSuccessHandler,
  onFailureHandler,
  onCancelHandler,
}: {
  data: PAYUIframeData;
  onSuccessHandler: () => void;
  onFailureHandler: () => void;
  onCancelHandler: () => void;
}) => {
  const handlers = {
    responseHandler: function (BOLT: any) {
      if (BOLT.response.txnStatus == "SUCCESS") {
        onSuccessHandler();
      }
      if (BOLT.response.txnStatus == "FAILED") {
        onFailureHandler();
      }
      if (BOLT.response.txnStatus == "CANCEL") {
        onCancelHandler();
      }
    },
    catchException: function (BOLT: any) {
      onFailureHandler();
    },
  };
  (window as any).payu.launch(data, handlers);
};
