// hooks/useReCaptcha.ts

import { useEffect, useState } from "react";

const RECAPTCHA_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const showBadge = () => {
  if (!(window as any).grecaptcha) return;
  (window as any).grecaptcha.ready(() => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
    if (!badge) return;
    badge.style.display = "block";
    badge.style.zIndex = "1";
  });
};

const hideBadge = () => {
  if (!(window as any).grecaptcha) return;
  (window as any).grecaptcha.ready(() => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
    if (!badge) return;
    badge.style.display = "none";
  });
};

const useReCaptcha = (): {
  reCaptchaLoaded: boolean;
  generateReCaptchaToken: (action: string) => Promise<string>;
} => {
  const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);

  // Load ReCaptcha script
  useEffect(() => {
    if (typeof (window as any) === "undefined" || reCaptchaLoaded) return;
    if ((window as any).grecaptcha) {
      showBadge();
      setReCaptchaLoaded(true);
      return;
    }
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
    script.addEventListener("load", () => {
      setReCaptchaLoaded(true);
      showBadge();
    });
    document.body.appendChild(script);
    return () => {
      script.removeEventListener("load", () => {
        setReCaptchaLoaded(true);
        showBadge();
      });
    };
  }, [reCaptchaLoaded]);

  // Hide badge when unmount
  useEffect(() => hideBadge, []);

  // Get token
  const generateReCaptchaToken = (action: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!reCaptchaLoaded) return reject(new Error("ReCaptcha not loaded"));
      if (typeof (window as any) === "undefined" || !(window as any).grecaptcha) {
        setReCaptchaLoaded(false);
        return reject(new Error("ReCaptcha not loaded"));
      }
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha.execute(RECAPTCHA_KEY, { action }).then((token: string) => {
          localStorage.setItem(RECAPTCHA_KEY, token);
          resolve(token);
        });
      });
    });
  };

  return { reCaptchaLoaded, generateReCaptchaToken };
};

export default useReCaptcha;
