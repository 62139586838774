import { MerchantType } from "lib/types/merchant";
import { parseMerchantData } from "lib/utils/merchant";
import { createContext, useCallback, useContext, useMemo, useReducer } from "react";

const Context = createContext<MerchantContext>({ state: {}, actions: {} } as MerchantContext);

export enum ActionType {
  SET_MERCHANT,
  SET_ADDRESS_CONFIG,
}

export type Action =
  | { type: ActionType.SET_MERCHANT; payload: MerchantType }
  | { type: ActionType.SET_ADDRESS_CONFIG; payload: string[] };

export interface MerchantState {
  merchant?: MerchantType;
  addressConfig: string[];
}

export interface MerchantContext {
  state: MerchantState;
  actions: {
    setMerchantData: (data: any) => void;
    setAddressConfig: (config: string[]) => void;
  };
}

function reducer(state: MerchantState, action: Action): MerchantState {
  switch (action.type) {
    case ActionType.SET_MERCHANT: {
      return {
        ...state,
        merchant: action.payload,
      };
    }
    case ActionType.SET_ADDRESS_CONFIG: {
      return {
        ...state,
        addressConfig: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

const merchantInitialState: MerchantState = {
  addressConfig: [],
};

export const MerchantProvider: React.FC<React.PropsWithChildren<{ initialState?: MerchantState }>> = ({
  initialState = merchantInitialState,
  children,
}) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState);

  const setMerchantData = useCallback((data: any) => {
    dispatch({
      type: ActionType.SET_MERCHANT,
      payload: parseMerchantData(data),
    });
  }, []);

  const setAddressConfig = useCallback((config: string[]) => {
    dispatch({
      type: ActionType.SET_ADDRESS_CONFIG,
      payload: config,
    });
  }, []);
  
  const actions = useMemo(
    () => ({
      setMerchantData,
      setAddressConfig,
    }),
    [setAddressConfig, setMerchantData],
  );

  return (
    <Context.Provider
      value={{
        state: reducerState,
        actions,
      }}>
      {children}
    </Context.Provider>
  );
};

export function useMerchantContext() {
  if (!Boolean(Context)) throw new Error("useMerchantContext must be used within a MerchantProvider");
  const merchantContext = useContext(Context);
  return merchantContext as MerchantContext;
}
