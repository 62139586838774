import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { DialogBody, DialogHeader, GenericDialog } from "components/common/dialogs/GenericDialog";
import CouponDialog from "components/checkout/dialogs/CouponDialog";
import { AppliedCouponType } from "lib/contexts/CheckoutProvider";
import {
  deleteRequest,
  getRequest,
  putRequest,
  postRequest,
  fetcher,
  staticOptions,
  publicFetcher,
} from "lib/core/apiClient";
import { getItems, findIdCouponByCode, isOnlyFreebieAutoApplied } from "lib/utils/checkout";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { Tag, ArrowRight, ChevronDown, ChevronRight, XCircle, Check } from "react-feather";
import { useAuthContext } from "lib/contexts/AuthProvider";
import useSWR, { mutate } from "swr";
import { errorToast } from "lib/utils/toasters";
import GenericButton from "components/common/buttons/GenericButton";
import InputField from "components/common/forms/InputField";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, eventTypes } from "lib/utils/constants";
import CelebrationPopup from "components/common/popups/CelebrationPopup";
import Price from "components/common/Price";
import SavingsIcon from "assests/images/savings-cookie.svg";
import AppliedItem from "components/common/AppliedItem";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { CouponDisplayConfig } from "lib/types/merchant";
import { SavingItemsType, ShippingHandlesType } from "lib/types/checkout";
import { ErrorType } from "lib/types/address";
import { CouponReplacementType } from "lib/types/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useSearchParams } from "react-router-dom";
import { getInvalidityReason, publishPostMessage } from "lib/utils/helpers";
import SwitchCouponDialog from "components/checkout/dialogs/SwitchCouponDialog";
import DiscountBadge from "assests/images/discount-badge.svg?react";
import LoaderDialog from "./dialogs/LoaderDialog";
import { currencyFormatter } from "lib/utils/formatters";
import CouponSummaryStrip from "components/common/CouponSummaryStrip";

interface CouponsProps {
  context?: "cart" | "checkout";
}

interface AvailableCoupons {
  shopflo: number;
  shopify: number;
}

let isCelebrationPopupFirstRendered = false;

const Coupons: React.FC<CouponsProps> = ({ context = "checkout" }) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page")?.toLowerCase();
  const {
    state: {
      appliedCoupons,
      checkoutId,
      coupons,
      isC2P,
      checkoutModal,
      checkoutView,
      initialCheckoutStep,
      checkoutUIMetadata,
    },
    actions: {
      updateCheckoutBasedOnCheckoutResponse,
      setCoupons,
      setCheckoutItems,
      setCheckoutModal,
      setCheckoutView,
      setShippingHandles,
    },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated, lockDiscounts },
  } = useAuthContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const [manualCoupon, setManualCoupon] = useState<string>("");
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCelebration, setOpenCelebration] = useState<boolean>(false);
  const [appliedCouponCode, setAppliedCouponCode] = useState<string>("");
  const [incomingCouponCode, setIncomingCouponCode] = useState<string>("");
  const [couponApplyError, setCouponApplyError] = useState<ErrorType>({ status: false, message: "" });
  const [couponReplacementPopupConfig, setCouponReplacementPopupConfig] = useState<CouponReplacementType>({
    showPopup: false,
    appliedDiscountCode: "",
    invalidDiscountCodes: [],
    invalidityReason: "NONE",
  });

  const { data: couponsData, isValidating: isCouponsLoading } = useSWR(
    !Boolean(lockDiscounts) && !Boolean(isC2P) && Boolean(checkoutId)
      ? Boolean(isAuthenticated)
        ? `/checkout/v1/checkout/${checkoutId}/discounts`
        : `/v1/checkout/${checkoutId}/discounts`
      : null,
    Boolean(isAuthenticated) ? fetcher : publicFetcher,
    staticOptions,
  );

  useEffect(() => {
    if (!Boolean(couponsData) || Boolean(isCouponsLoading)) return;
    setCoupons(couponsData);
  }, [couponsData, isCouponsLoading]);

  useEffect(() => {
    const hasAutoAppliedCoupon = appliedCoupons?.find((coupon) => coupon.autoApplied);
    if (Boolean(hasAutoAppliedCoupon) && !isCelebrationPopupFirstRendered) {
      setIncomingCouponCode(hasAutoAppliedCoupon?.code ?? "");
      setAppliedCouponCode("");
      setOpenCelebration(true);
      isCelebrationPopupFirstRendered = true;
    }
  }, []);

  const savingItems: SavingItemsType = useMemo(() => {
    let count = 0;
    let manualFreebieCount = 0;

    const savings = appliedCoupons?.reduce(
      (
        acc: number,
        {
          discountValue,
          isFreebie,
          autoApplied,
          discountPercentage,
          isReward,
          couponRewardConfig,
          isRemovable,
        },
      ) => {
        if (isReward) {
          return acc;
        }
        if (!isFreebie) {
          count++;
        }
        if (isFreebie) {
          if (discountPercentage !== 100) {
            count++;
          } else if (!autoApplied || isRemovable) {
            manualFreebieCount++;
            count++;
          }
        }
        if (isReward) {
          const discountAmount = couponRewardConfig?.amount ?? 0;
          return acc + discountAmount;
        }

        return acc + discountValue;
      },
      0,
    );
    return { savings: savings, count: count, manualFreebieCount: manualFreebieCount };
  }, [appliedCoupons, coupons, checkoutView]);

  const availableCoupons = useMemo(() => {
    let couponDetails: AvailableCoupons = { shopflo: 0, shopify: 0 };
    if (!Boolean(coupons)) return couponDetails;
    let applicableCoupons = coupons.filter(
      (coupon: any) => coupon.is_applicable || coupon?.un_applicability_reason?.reason === "UARC_002",
    );

    couponDetails.shopify = applicableCoupons.filter((coupon: any) =>
      coupon?.coupon_details?.tags?.includes("SHOPIFY"),
    ).length;

    couponDetails.shopflo = applicableCoupons.filter(
      (coupon: any) =>
        coupon?.coupon_details?.tags?.includes("SHOPFLO") ||
        coupon?.coupon_details?.tags?.includes("PLATFORM"),
    ).length;

    return couponDetails;
  }, [coupons]);

  const removableAppliedCoupon = useMemo(() => {
    if (!merchant?.discountSettings?.showMaxDiscount) return null;
    if (appliedCoupons.length < 1) return null;
    const sortedAppliedCoupons = appliedCoupons
      .filter((coupon) => !coupon?.isFreebie)
      .sort((coupon, compareCoupon) => (coupon?.discountValue < compareCoupon?.discountValue ? 1 : -1))
      .sort((coupon, compareCoupon) => (coupon?.isRemovable ? -1 : compareCoupon?.isRemovable ? 1 : 0));
    return sortedAppliedCoupons?.[0];
  }, [appliedCoupons]);

  const maxAvailableCoupon = useMemo(() => {
    if (!merchant?.discountSettings?.showMaxDiscount) return null;
    if (availableCoupons.shopflo < 1) return null;
    const applicableCoupons = coupons.filter(
      (coupon) =>
        Boolean(coupon?.is_applicable) &&
        !Boolean(coupon?.coupon_details?.already_applied) &&
        Boolean(coupon?.coupon_details?.coupon_type !== "BXGY") &&
        Boolean(coupon?.coupon_details?.coupon_type !== "CALLOUT_CARD"),
    );
    if (applicableCoupons.length < 1) return null;
    return applicableCoupons?.sort((coupon, compareCoupon) =>
      coupon?.total_discount < compareCoupon?.total_discount ? 1 : -1,
    )?.[0];
  }, [coupons, appliedCoupons]);

  const isCouponStripAnimationEnabled = useCallback(() => {
    const { shopflo, shopify } = availableCoupons;

    if (shopflo === 0 && shopify === 0) return false;

    const onlyFreebiesApplied = () =>
      appliedCoupons.every((coupon: AppliedCouponType) => coupon?.isFreebie) &&
      Boolean(appliedCoupons?.length);

    return (
      isOnlyFreebieAutoApplied(appliedCoupons) || !Boolean(appliedCoupons?.length) || onlyFreebiesApplied()
    );
  }, [appliedCoupons, availableCoupons]);

  const getCouponDisplayType = useCallback(() => {
    //Check if the coupons are enabled for particluar page
    const currentCouponDisplayConfig = checkoutUIMetadata?.couponDisplayConfig?.find(
      (config: CouponDisplayConfig) => {
        if (checkoutView === "ADDRESS_LIST") {
          return config.type === "ORDER_SUMMARY";
        } else if (page === "cart") {
          return config.type === "CART";
        } else {
          return config.type === checkoutView;
        }
      },
    );

    const areCouponsEnabled = Boolean(currentCouponDisplayConfig?.isDisplayEnabled);

    if (!Boolean(areCouponsEnabled) || merchant?.couponStackLength === 0) return null;

    //Get the display type of the coupons
    const displayType = currentCouponDisplayConfig?.displayType;

    //Conditions for coupon dialog to be disabled
    const isCouponDialogDisabled = !merchant?.isDiscountEnabled;

    if (isCouponDialogDisabled) {
      return null;
    }
    return displayType;
  }, [coupons, merchant, checkoutView, page, checkoutUIMetadata]);

  const handleDiscountApply = async (
    code: string,
    isManual: boolean = false,
    overrideRemoval: boolean = false,
  ) => {
    if (!Boolean(code?.trim())) return;
    const couponId = findIdCouponByCode(coupons, code);
    //Coupon entered/selected event
    sendAnalyticsEvent({
      eventName: isManual ? analyticsEvents.FLO_COUPON_ENTERED : analyticsEvents.FLO_COUPON_SELECTED,
      eventType: "click",
      metaData: {
        couponData: {
          coupon_code: code,
          coupon_id: Boolean(couponId) ? couponId : "NA",
        },
      },
    });

    const payload = {
      discount_code: code?.trim(),
    };

    try {
      overrideRemoval ? setCheckoutModal("LOADER") : setIsLoading(true);
      let response;
      if (isAuthenticated) {
        response = await postRequest(
          `/checkout/v1/checkout/${checkoutId}/discounts${overrideRemoval ? "?raise_on_removal=false" : ""}`,
          payload,
        );
        const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
        setCoupons(discountResponse);
      } else {
        response = await putRequest(
          `/v1/checkout/${checkoutId}/discounts${overrideRemoval ? "?raise_on_removal=false" : ""}`,
          payload,
          "CHECKOUT_PUBLIC",
        );
        const discountResponse = await getRequest(`/v1/checkout/${checkoutId}/discounts`, "CHECKOUT_PUBLIC");
        setCoupons(discountResponse);
      }
      if (!response) {
        errorToast(t("coupon_not_found"));
        return;
      }
      if (Boolean(response?.metadata?.show_discount_warning)) {
        setCouponReplacementPopupConfig({
          showPopup: Boolean(response?.metadata?.show_discount_warning),
          appliedDiscountCode: code?.trim(),
          invalidDiscountCodes:
            response?.metadata?.invalid_discount_code &&
            Boolean(response?.metadata?.invalid_discount_code?.length)
              ? response?.metadata?.invalid_discount_code
              : [],
          invalidityReason: getInvalidityReason(
            Boolean(response?.metadata?.invalid_discount_reasons?.length)
              ? response?.metadata?.invalid_discount_reasons
              : [],
          ),
        });
        overrideRemoval ? setCheckoutModal("NONE") : setIsLoading(false);
        return;
      }

      // send coupon applied to cart
      if (context === "checkout") {
        publishPostMessage(eventTypes.COUPONS_UPDATED, {});
      }
      setAppliedCouponCode(code);
      setIncomingCouponCode("");
      setManualCoupon("");
      setCouponApplyError((oldValues: ErrorType) => ({ ...oldValues, status: false, message: "" }));

      updateCheckoutBasedOnCheckoutResponse(response);

      overrideRemoval ? setCheckoutModal("NONE") : setIsLoading(false);
      setCheckoutModal("NONE");
      setOpenCelebration(true);

      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable)) {
        if (Boolean(checkoutView === "PAYMENTS") && Boolean(initialCheckoutStep !== "PAYMENTS")) {
          setCheckoutView("ADDRESS_LIST");
        }
        if (Boolean(checkoutView === "PAYMENTS") && Boolean(initialCheckoutStep === "PAYMENTS")) {
          setCheckoutModal("ADDRESS_LIST");
        }
        errorToast(t("serviceability_error"), 5000);
        return;
      }

      const shippingHandles = response?.metadata?.available_shipping_handles;
      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
      } else {
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        mutate(`UPI_INTENT`);
      }

      //coupon success event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_COUPON_SUCCESS,
        eventType: "flo_action",
        metaData: {
          couponData: {
            coupon_code: code,
            coupon_id: Boolean(couponId) ? couponId : "NA",
          },
        },
      });
    } catch (e: any) {
      overrideRemoval ? setCheckoutModal("NONE") : setIsLoading(false);
      const errorMsg = e?.response?.data?.error;
      if (Boolean(isManual)) {
        setCouponApplyError((oldValues: ErrorType) => ({ ...oldValues, status: true, message: errorMsg }));
      } else {
        errorToast(errorMsg ?? t("coupon_not_found"));
      }
      //coupon failed event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_COUPON_FAILED,
        eventType: "flo_action",
        metaData: {
          couponData: {
            coupon_code: code,
            coupon_id: Boolean(couponId) ? couponId : "NA",
            failed_reason: errorMsg,
          },
        },
      });
      console.error(e);
    }
  };

  const handleDeleteCoupon = async (couponToBeDeleted: string) => {
    if (!Boolean(appliedCoupons?.find((coupon) => coupon?.code === couponToBeDeleted))) return;
    const payload = {
      discount_code: couponToBeDeleted,
    };

    try {
      setIsLoading(true);
      let response: any;
      let discountResponse: any;
      if (isAuthenticated) {
        response = await deleteRequest(`/checkout/v1/checkout/${checkoutId}/discounts`, payload);
        discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
        setCoupons(discountResponse);
      } else {
        response = await deleteRequest(`/v1/checkout/${checkoutId}/discounts`, payload, "CHECKOUT_PUBLIC");
        const discountResponse = await getRequest(`/v1/checkout/${checkoutId}/discounts`, "CHECKOUT_PUBLIC");
        setCoupons(discountResponse);
      }

      updateCheckoutBasedOnCheckoutResponse(response);

      setIsLoading(false);

      if (!Boolean(response?.discounts)) {
        setManualCoupon("");
      }
      if (Boolean(response?.items)) {
        setCheckoutItems(getItems(response?.items));
      }
      const couponId = findIdCouponByCode(coupons, couponToBeDeleted);
      //coupon success event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_COUPON_REMOVED,
        eventType: "flo_action",
        metaData: {
          couponData: {
            coupon_code: couponToBeDeleted,
            coupon_id: Boolean(couponId) ? couponId : "NA",
          },
        },
      });

      const shippingHandles = response?.metadata?.available_shipping_handles;
      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
      } else {
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        mutate(`UPI_INTENT`);
      }
    } catch (e) {
      setIsLoading(false);
      errorToast(t("delete_discount_error"));
      console.error(e);
    }
  };

  const closeSwitchDiscountPopup = () => {
    setCouponReplacementPopupConfig({
      showPopup: false,
      appliedDiscountCode: "",
      invalidDiscountCodes: [],
      invalidityReason: "NONE",
    });
  };

  const isBackgroundGradientEnabled = () => {
    const coupon = getCouponDisplayType();
    if (!Boolean(appliedCoupons?.length)) return false;
    if (coupon === "INPUT") return true;
    if (Boolean(couponApplyError?.status)) return false;
    if (Boolean(isOnlyFreebieAutoApplied(appliedCoupons))) return false;
    return true;
  };
  if (Boolean(isC2P) && !Boolean(appliedCoupons?.length)) return <></>;

  if (Boolean(isCouponsLoading))
    return (
      <div className="relative flex min-h-[56px] items-center rounded-2xl border border-gray-light px-3">
        <Tag className="top-[18px] left-4 h-4 w-4 text-gray-dark" strokeWidth={2.5} />
        <div className="flex flex-col pl-2">
          <h3 className="shimmer text-sm font-normal text-gray-dark">{t("loading_coupons")}</h3>
        </div>
      </div>
    );
  if (!Boolean(merchant?.isDiscountEnabled) && Boolean(appliedCoupons?.length))
    return (
      <div className="rounded-2xl bg-yay-lighter">
        <CouponSummaryStrip
          showAppliedCount={true}
          showRewards={true}
          savingItems={savingItems}
          couponDisplayType={getCouponDisplayType() ?? "NONE"}
          handleDeleteCoupon={handleDeleteCoupon}
        />
      </div>
    );
  return (
    <>
      <div
        className={`flex flex-col items-start justify-center rounded-2xl bg-none text-coal-dark ${
          Boolean(getCouponDisplayType())
            ? isBackgroundGradientEnabled()
              ? "bg-yay-lighter"
              : ""
            : "bg-yay-lighter"
        }`}>
        {!Boolean(isC2P) &&
          Boolean(getCouponDisplayType()) &&
          (getCouponDisplayType() === "STRIP" && Boolean(coupons?.length) ? (
            <div
              className={`isolate flex w-full flex-col !overflow-hidden rounded-2xl border-[2px] border-yay-light ${
                isCouponStripAnimationEnabled() ? "text-yay-dark" : "text-coal-dark"
              }`}>
              {maxAvailableCoupon && Boolean(maxAvailableCoupon) ? (
                <div
                  className={`relative flex h-[4.5rem] w-full flex-row space-x-1 items-center justify-between rounded-2xl bg-white cursor-pointer`}
                  id="flo_coupons_applied">
                  {/* Animation div */}

                  <div
                    className="absolute z-10 left-0 top-0 h-full w-full animate-slide
                    bg-gradient-to-l from-[#2b874a03] from-0% via-[#2c874a12] via-[#2c874a12] via-[#2c874a21] via-[#2c874a21] via-20% via-40% via-60% via-80% to-[#2c874a03] to-100% !bg-[length:30%_100%] !bg-no-repeat"></div>
                  {/* Animation div */}
                  <div
                    className="w-full z-20 flex space-x-1 py-3 pl-3"
                    onClick={() => {
                      setCheckoutModal("DISCOUNT_LIST");
                      setCouponApplyError((oldValues: ErrorType) => ({
                        ...oldValues,
                        status: false,
                        message: "",
                      }));
                    }}>
                    <div className="flex justify-start h-full text-yay-dark items-start py-0.5">
                      <Tag className="h-4 min-h-4 w-4 min-w-4" />
                    </div>
                    <div className="flex flex-col h-full space-y-[3px] justify-start items-start w-full">
                      <p className="text-sm font-medium text-yay-dark">
                        {maxAvailableCoupon?.coupon_details?.coupon_type === "REWARD_AS_DISCOUNT" &&
                        maxAvailableCoupon?.coupon_details?.coupon_reward_config?.wallet_type === "TICKERTAPE"
                          ? maxAvailableCoupon?.coupon_details?.header
                          : t("save") + " " + currencyFormatter(maxAvailableCoupon?.total_discount)}
                      </p>
                      <p className="pl-1 text-xs font-medium text-coal-dark">
                        <span className="text-coal-light font-normal">{t("with")}</span>
                        {" '" +
                          (Boolean(maxAvailableCoupon?.coupon_details?.title)
                            ? maxAvailableCoupon?.coupon_details?.title
                            : maxAvailableCoupon?.coupon_details?.coupon_code) +
                          "'"}
                      </p>
                      <div className="pl-1 text-xs text-coal-dark font-medium flex items-end cursor-pointer">
                        <span>
                          {Boolean(availableCoupons?.shopflo + availableCoupons?.shopify)
                            ? t("view_other_coupons")
                            : t("enter_a_coupon_code")}
                        </span>
                        <ChevronRight className="w-3 h-3" />
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => handleDiscountApply(maxAvailableCoupon?.coupon_details?.coupon_code)}
                    className="flex z-20 justify-start py-3 pr-3 items-start h-full w-[4.5rem]">
                    <span className="text-xs font-medium text-yay-dark cursor-pointer rounded-lg px-3 py-2 border border-yay-dark bg-yay-lighter">
                      {t("apply")}
                    </span>
                  </div>
                </div>
              ) : removableAppliedCoupon && Boolean(removableAppliedCoupon) ? (
                <div
                  className={`relative flex h-[4.5rem] w-full flex-row space-x-1 items-center justify-between rounded-2xl bg-white cursor-pointer`}
                  id="flo_coupons_applied">
                  <div
                    className="w-full flex space-x-1 py-3 pl-3"
                    onClick={() => {
                      setCheckoutModal("DISCOUNT_LIST");
                      setCouponApplyError((oldValues: ErrorType) => ({
                        ...oldValues,
                        status: false,
                        message: "",
                      }));
                    }}>
                    <div className="flex justify-start h-full text-yay-dark items-start py-0.5">
                      <Tag className="h-4 min-h-4 w-4 min-w-4" />
                    </div>
                    <div className="flex flex-col h-full space-y-[3px] justify-start items-start w-full">
                      <p className="text-sm font-medium text-yay-dark">
                        {removableAppliedCoupon?.isReward &&
                        removableAppliedCoupon?.couponRewardConfig?.walletType === "TICKERTAPE"
                          ? removableAppliedCoupon?.rewardData?.header
                          : currencyFormatter(removableAppliedCoupon?.discountValue) + " " + t("savings")}
                      </p>
                      <p className="pl-1 text-xs font-medium text-yay-dark inline-flex gap-1 items-center">
                        <span className="text-coal-light font-normal">{t("with")}</span>
                        {" '" +
                          (Boolean(removableAppliedCoupon?.title)
                            ? removableAppliedCoupon?.title
                            : removableAppliedCoupon?.code) +
                          "'"}
                        <Check className="-ml-[3px] h-3.5 w-3.5" strokeWidth={2.75} />
                      </p>
                      <div className="pl-1 text-xs text-coal-dark font-medium flex items-end cursor-pointer">
                        <span>
                          {Boolean(availableCoupons?.shopflo + availableCoupons?.shopify)
                            ? t("view_other_coupons")
                            : t("enter_a_coupon_code")}
                        </span>
                        <ChevronRight className="w-3 h-3" />
                      </div>
                    </div>
                  </div>
                  {removableAppliedCoupon?.isRemovable ? (
                    <div
                      onClick={() => handleDeleteCoupon(removableAppliedCoupon?.code)}
                      className="flex justify-start py-3 pr-3 items-start h-full w-[4.5rem]">
                      <span className="text-sm font-semibold text-yay-dark cursor-pointer rounded-lg py-2">
                        {t("remove")}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="flex justify-start py-3 pr-3 items-start h-full w-[4.5rem]"
                      onClick={() => {
                        setCheckoutModal("DISCOUNT_LIST");
                        setCouponApplyError((oldValues: ErrorType) => ({
                          ...oldValues,
                          status: false,
                          message: "",
                        }));
                      }}>
                      <span className="text-sm font-semibold text-yay-dark cursor-pointer rounded-lg py-2">
                        {t("coupon_applied")}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`relative flex h-[3.25rem] w-full	cursor-pointer flex-row items-center justify-between  rounded-2xl bg-white px-3 py-4`}
                  id="flo_coupons_select"
                  onClick={() => {
                    setCheckoutModal("DISCOUNT_LIST");
                    setCouponApplyError((oldValues: ErrorType) => ({
                      ...oldValues,
                      status: false,
                      message: "",
                    }));
                  }}>
                  {/* Animation div */}
                  {isCouponStripAnimationEnabled() && (
                    <div
                      className="absolute left-0 top-0 h-full w-full animate-slide
                    bg-gradient-to-l from-[#2b874a03] from-0% via-[#2c874a12] via-[#2c874a12] via-[#2c874a21] via-[#2c874a21] via-20% via-40% via-60% via-80% to-[#2c874a03] to-100% !bg-[length:30%_100%] !bg-no-repeat"></div>
                  )}
                  {/* Animation div */}

                  <>
                    <div className="flex w-3/5 items-start space-x-2 ">
                      <Tag className="h-4 w-4" strokeWidth={2.5} />
                      <div className="flex flex-col ">
                        <h3 className="text-sm font-medium">{t("coupons")}</h3>
                      </div>
                    </div>
                    {availableCoupons?.shopflo > 0 && (
                      <div className="ml-auto pr-1 text-sm font-normal text-yay-dark">
                        {t("x_symbol_available", {
                          count: availableCoupons?.shopflo,
                          symbol: availableCoupons?.shopify > 0 ? "+" : "",
                        })}
                      </div>
                    )}
                    {/* Always show coupon list opening chevron */}
                    {<ArrowRight className="h-4 w-4" />}
                  </>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`border-box relative flex w-full gap-1.5 px-0 ${
                !Boolean(coupons?.length) && Boolean(appliedCoupons?.length) ? "" : ""
              }`}>
              <Tag className={`absolute top-[18px] left-4 z-10 h-4 w-4 text-coal-dark`} strokeWidth={2.5} />
              <InputField
                type="text"
                placeholder={t("enter_coupon_code")}
                name="coupon-code"
                id="coupon-code"
                maxLength={50}
                autoComplete="off"
                autoFocus={false}
                value={manualCoupon}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setManualCoupon(e.target.value.trim())}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  e.key === "Enter" && handleDiscountApply(manualCoupon, true)
                }
                customClass={`h-[3.25rem] border border-gray-light !rounded-2xl  placeholder-gray-dark text-sm font-normal text-coal-light w-full !pl-[2.4rem]  ${
                  Boolean(manualCoupon?.length) ? "!rounded-r-lg" : ""
                }`} // on focus remove 2px padding from left to avoid placeholder shifing because of the ring
                showLabel={false}
                tabIndex={-1}
                error={couponApplyError}
              />
              {Boolean(manualCoupon?.length) && (
                <GenericButton
                  buttonText=""
                  height="h-12"
                  width=""
                  customClass="flex items-center justify-center rounded-l-lg  w-14 rounded-2xl bg-primary-dark"
                  iconComponent={<ArrowRight className="h-4 w-4 text-btnPrimaryText" />}
                  onClick={() => handleDiscountApply(manualCoupon, true)}
                />
              )}
            </div>
          ))}
        {Boolean(appliedCoupons?.length) && Boolean(!isOnlyFreebieAutoApplied(appliedCoupons)) && (
          <>
            <CouponSummaryStrip
              showAppliedCount={true}
              showRewards={true}
              showSavings={
                Boolean(getCouponDisplayType() === "STRIP" && !Boolean(coupons?.length)) ||
                getCouponDisplayType() !== "STRIP" ||
                !merchant?.discountSettings?.showMaxDiscount ||
                Boolean(
                  getCouponDisplayType() === "STRIP" &&
                    Boolean(appliedCoupons?.length) &&
                    (Boolean(maxAvailableCoupon) ||
                      appliedCoupons?.length > 1 ||
                      Boolean(savingItems.manualFreebieCount)),
                )
              }
              showAppliedCoupons={Boolean(
                getCouponDisplayType() === "STRIP" &&
                  Boolean(appliedCoupons?.length) &&
                  Boolean(
                    Boolean(!Boolean(coupons?.length) && appliedCoupons?.length > 0) ||
                      Boolean(maxAvailableCoupon) ||
                      appliedCoupons?.length > 1 ||
                      Boolean(savingItems.manualFreebieCount),
                  ),
              )}
              savingItems={savingItems}
              couponDisplayType={getCouponDisplayType() ?? "NONE"}
              handleDeleteCoupon={handleDeleteCoupon}
              showIcon={true}
            />
          </>
        )}

        {Boolean(openCelebration) && (
          <CelebrationPopup
            isOpen={true}
            closePopup={() => {
              setOpenCelebration(false);
              setAppliedCouponCode("");
              setIncomingCouponCode("");
            }}
            appliedCouponCode={appliedCouponCode}
            incomingCouponCode={incomingCouponCode}
          />
        )}
        <GenericDialog
          isOpen={couponReplacementPopupConfig?.showPopup}
          translateAxis="y"
          customClass="overflow-scroll md:!top-auto md:absolute"
          dialogOverlay={true}
          modalType="REPLACE_COUPON">
          <SwitchCouponDialog
            appliedDiscountCode={couponReplacementPopupConfig?.appliedDiscountCode}
            invalidDiscountCodes={couponReplacementPopupConfig?.invalidDiscountCodes}
            closePopup={closeSwitchDiscountPopup}
            applyCoupon={async () => {
              closeSwitchDiscountPopup();
              await handleDiscountApply(couponReplacementPopupConfig?.appliedDiscountCode, false, true);
            }}
            invalidReason={couponReplacementPopupConfig?.invalidityReason}
          />
        </GenericDialog>
        <LoaderDialog title={t("replacing_coupons")} />

        {isLoading && <OverlaySpinner />}

        <GenericDialog
          isOpen={checkoutModal === "DISCOUNT_LIST"}
          setIsOpen={(value: boolean) => {
            setCheckoutModal(value ? "DISCOUNT_LIST" : "NONE");
          }}
          translateAxis="x"
          modalType="DISCOUNT_LIST">
          <CouponDialog
            setOpenDialog={() => {
              setCheckoutModal("NONE");
            }}
            handleDiscountApply={handleDiscountApply}
            handleDeleteCoupon={handleDeleteCoupon}
            isCouponsLoading={isCouponsLoading}
            couponApplyError={couponApplyError}
            setCouponApplyError={setCouponApplyError}
            savingItems={savingItems}
            couponDisplayType={getCouponDisplayType() ?? "NONE"}
          />
        </GenericDialog>
      </div>
    </>
  );
};

export default Coupons;
